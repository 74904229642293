import CreateNewApp from "./CreateNewApp";
import AppsList from "./AppsList";

function AppsManager() {
	return (
		<div>
			<CreateNewApp />
			<AppsList />
		</div>
	);
}

export default AppsManager;
