interface IPayload {
	index: number;
	timestamp: number;
	data: any;
}

function assertIsIPayload(obj: any) {
	if (typeof obj.index !== "number") throw new Error(`Expected payload object to have "index" value`);
	if (typeof obj.timestamp !== "number") throw new Error(`Expected payload object to have "timestamp" value`);
	if (typeof obj.data === "undefined" || obj.data === null) throw new Error(`Expected payload object to have "data" value`);
	return true;
}

export default IPayload;
export { assertIsIPayload };
