import { useCallback, useState } from "react";
import IPayload from "../core/IPayload";
import Table from "./Table";
import JsonDetail from "./JsonDetail";
import { getNestedValue } from "../util/objects";
import { usePayloadsContext } from "../contexts/PayloadsContext";
import ShowFieldsInput from "./ShowFieldsInput";
import FilterStringInput, { FilterFunction } from "./FilterStringInput";
import PayloadsRefreshControls from "./PayloadsRefreshControls";

function PayloadList() {
	const payloadsContext = usePayloadsContext();
	const payloads = payloadsContext.payloads;
	const [filterFunction, setFilterFunction] = useState<FilterFunction<IPayload> | null>(null);
	const [fieldsToShow, setFieldsToShow] = useState<string[]>([]);
	const [markedPayloadIndexes, setMarkedPayloadIndexes] = useState<number[]>([]);

	const filteredPayloads: IPayload[] = (filterFunction && filterFunction(payloads)) || payloads;

	const onFieldsToShowInputChanged = useCallback((newFieldsToShow: string[]) => setFieldsToShow(newFieldsToShow), [setFieldsToShow]);
	const onFilterStringInputChanged = useCallback(
		(newFilterFunction: FilterFunction<IPayload>) => {
			setFilterFunction(() => newFilterFunction);
		},
		[setFilterFunction]
	);

	if (!payloadsContext.selectedAppId) {
		return <div>Select an app...</div>;
	}

	const onRowClick = (payload: IPayload) => {
		const markedIndex = markedPayloadIndexes.indexOf(payload.index);
		if (markedIndex === -1) {
			setMarkedPayloadIndexes([...markedPayloadIndexes, payload.index]);
		} else {
			setMarkedPayloadIndexes(markedPayloadIndexes.filter((index) => index !== payload.index));
		}
	};

	return (
		<div>
			<div>
				<div>
					<PayloadsRefreshControls />
				</div>
				<FilterStringInput appId={payloadsContext.selectedAppId} keyPrefix="data." onChange={onFilterStringInputChanged} />
				<ShowFieldsInput appId={payloadsContext.selectedAppId} onChange={onFieldsToShowInputChanged} />
				<div>
					<button disabled={payloadsContext.isLoading || payloads.length === 0} onClick={() => payloadsContext.clearPayloads()}>
						Clear list
					</button>
				</div>
			</div>
			<div>
				<Table<IPayload>
					data={filteredPayloads}
					idFunction={(payload) => payload.index.toString()}
					sortField={(payload) => payload.index}
					sortInvert
					onRowClick={onRowClick}
					rowAttributesFn={(payload) => {
						const isMarked = markedPayloadIndexes.indexOf(payload.index) !== -1;
						return { style: { backgroundColor: isMarked ? "black" : "" } };
					}}
					columns={[
						{
							columnName: "Index",
							content: (payload) => payload.index.toString(),
							hideColumnName: true,
						},
						{
							columnName: "Time info",
							content: (payload) => <div style={{ minWidth: "160px" }}>{getPrettyDate(payload.timestamp)}</div>,
							hideColumnName: true,
						},
						...fieldsToShow.map((fieldToShow) => ({
							columnName: fieldToShow,
							content: (payload: IPayload) => (getNestedValue(payload.data, fieldToShow) ?? "").toString(),
						})),
						{
							columnName: "Details",
							content: (payload) => (
								<JsonDetail
									onClick={(event: MouseEvent) => event.preventDefault()}
									style={{ margin: "6px 0" }}
									json={{ data: payload.data }}
								/>
							),
						},
					]}
				/>
			</div>
		</div>
	);
}
export default PayloadList;

function getPrettyDate(timestamp: number): string {
	const date = new Date(timestamp);

	var today = new Date();
	var isToday = today.toDateString() === date.toDateString();
	if (isToday) {
		return "Today, " + date.toLocaleTimeString();
	}

	return date.toLocaleString();
}
