import request from "../util/requests";
import IPayload, { assertIsIPayload } from "./IPayload";
import IPayloadApp, { assertIsIPayloadApp } from "./IPayloadApp";

async function loadLastPayloads(appId: string, startIndex?: number): Promise<IPayload[]> {
	let url = `/inspect/${appId}`;
	if (startIndex !== undefined) {
		url += "?startIndex=" + startIndex;
	}

	try {
		const response = await request("GET", url);
		if (response.status > 299 || response.status < 200) triggerError(url, response);
		const result = await response.json();
		if (!Array.isArray(result.payloads)) throw new Error("Unexpected response from /inspect");
		for (const payload of result.payloads) {
			assertIsIPayload(payload);
		}
		return result.payloads as IPayload[];
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
		return [];
	}
}

async function createApp(appId: string): Promise<void> {
	try {
		const url = "/createApp";
		const response = await request("POST", url, {
			body: JSON.stringify({
				appId: appId,
			}),
		});
		if (response.status !== 204) triggerError(url, response);
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
	}
}

async function deleteApp(appId: string): Promise<void> {
	try {
		const url = "/deleteApp";
		const response = await request("DELETE", url, {
			body: JSON.stringify({
				appId: appId,
			}),
		});
		if (response.status !== 204) triggerError(url, response);
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
	}
}

async function listApps(): Promise<IPayloadApp[]> {
	try {
		const url = "/listApps";
		const response = await request("GET", url);
		if (response.status !== 200) triggerError(url, response);
		const result = await response.json();
		for (const app of result.apps) {
			assertIsIPayloadApp(app);
		}
		return result.apps as IPayloadApp[];
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
		return [];
	}
}

async function triggerError(url: string, response: Response) {
	console.error("Response: ", await response.text());
	throw new Error(`Didn't get 2xx status code for request "${url}"`);
}

export { loadLastPayloads, createApp, deleteApp, listApps };
