import { useState } from "react";

function JsonDetail({ json, ...children }: { json: { [key: string]: any }; [key: string]: any }) {
	const [expandedKeys, setExpandedKeys] = useState<{ [key: string]: boolean }>({});

	const toggleKeyExpanded = (toToggleKey: string) => {
		const newKeys = { ...expandedKeys };
		if (expandedKeys[toToggleKey]) {
			delete newKeys[toToggleKey];
			setExpandedKeys(newKeys);
		} else {
			newKeys[toToggleKey] = true;
			setExpandedKeys(newKeys);
		}
	};

	if (Object.keys(json).length === 0) {
		return <div style={{ marginLeft: "24px" }}>Empty</div>;
	}

	return (
		<div {...children}>
			{Object.keys(json)
				.sort()
				.map((key) => {
					const isExpandable = typeof json[key] === "object" && json[key] !== null;
					return (
						<div key={key}>
							<span
								style={{
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									maxWidth: "1200px",
									display: "inline-block",
									cursor: isExpandable ? "pointer" : "auto",
								}}
								// onClick={isExpandable ? () => toggleKeyExpanded(key) : () => {}}
								onClick={(event) => {
									if (!isExpandable) return;
									toggleKeyExpanded(key);
									event.preventDefault();
								}}
							>
								{isExpandable ? "⚯ " : "⚬ "}
								<b>{key}</b>: <span>{toSimpleString(json[key])}</span>
							</span>
							{expandedKeys[key] && <JsonDetail style={{ marginLeft: "24px" }} json={json[key]} />}
						</div>
					);
				})}
		</div>
	);
}

function toSimpleString(obj: any, showPreview?: boolean): string {
	showPreview = showPreview ?? true;
	if (obj === undefined) {
		return "undefined";
	}
	if (obj === null) {
		return "null";
	}
	if (Array.isArray(obj)) {
		if (!showPreview) {
			return `Array [${obj.length}]`;
		}
		return `[${obj.map((val) => toSimpleString(val, false)).join(", ")}]`;
	}
	if (typeof obj === "object") {
		if (!showPreview) {
			return `Object {${Object.keys(obj).length}}`;
		}
		return (
			"{" +
			Object.keys(obj)
				.sort()
				.map((key) => `${key}: ${toSimpleString(obj[key], false)}`)
				.join(", ") +
			"}"
		);
	}
	if (typeof obj === "string") {
		return `"${obj}"`;
	}
	if (typeof obj === "number" || typeof obj === "boolean") {
		return obj.toString();
	}
	return "⚠ Unexpected type: " + obj.toString();
}

export default JsonDetail;
