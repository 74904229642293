import request from "../util/requests";

async function isAuthenticated(): Promise<boolean> {
	try {
		const response = await request("GET", "/auth");
		const body = await response.json();
		return body.authenticated as boolean;
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
		return false;
	}
}

async function authenticate(password: string): Promise<void> {
	try {
		const response = await request("POST", "/auth", {
			body: JSON.stringify({
				password: password,
			}),
		});
		if (response.status !== 204) {
			const body = await response.json();
			throw new Error(body.error);
		}
	} catch (e) {
		alert("Communication with the server failed");
		console.error(e);
	}
}

export { isAuthenticated, authenticate };
