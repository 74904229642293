import React, { FormEvent, FormEventHandler } from "react";

export default function Form({ children, onSubmit, ...rest }: { onSubmit?: FormEventHandler<HTMLFormElement>; children: any; [key: string]: any }) {
	function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		onSubmit && onSubmit(event);
	}

	return (
		<form onSubmit={handleSubmit} {...rest}>
			{children}
		</form>
	);
}
