import { useCallback, useEffect, useState } from "react";
import { usePayloadsContext } from "../contexts/PayloadsContext";

const LOCALSTORAGE_SELECTED_APP_ID = "selected_app_id";

function AppsList() {
	const payloadsContext = usePayloadsContext();
	const [loadingState, setLoadingState] = useState<string>("not loaded");
	const [onloadSelectAppId, setOnloadSelectAppId] = useState<string | null>(null);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const apps = payloadsContext.apps;

	const loadApps = useCallback(async () => {
		setLoadingState("loading");
		setIsDisabled(true);
		await payloadsContext.loadApps();
		setLoadingState("loaded");
		setIsDisabled(false);
	}, [payloadsContext]);

	const removeApp = useCallback(
		async (appId: string): Promise<void> => {
			if (window.confirm(`Are you sure you want to delete app "${appId}"?`)) {
				setIsDisabled(true);
				await payloadsContext.removeApp(appId);
				setIsDisabled(false);
			}
		},
		[payloadsContext]
	);

	const selectApp = useCallback(
		async (appId: string): Promise<void> => {
			setIsDisabled(true);
			localStorage.setItem(LOCALSTORAGE_SELECTED_APP_ID, appId);
			await payloadsContext.selectApp(appId);
			setIsDisabled(false);
		},
		[payloadsContext]
	);

	useEffect(() => {
		if (loadingState === "not loaded") {
			(async function () {
				await loadApps();
				const storedAppId = localStorage.getItem(LOCALSTORAGE_SELECTED_APP_ID) || null;
				if (storedAppId !== null) {
					setOnloadSelectAppId(storedAppId);
				}
			})();
		}

		if (onloadSelectAppId) {
			setOnloadSelectAppId(null);
			if (payloadsContext.apps.find((app) => app.appId === onloadSelectAppId)) {
				selectApp(onloadSelectAppId);
			} else {
				localStorage.removeItem(LOCALSTORAGE_SELECTED_APP_ID);
			}
		}
	}, [loadApps, loadingState, payloadsContext.apps, selectApp, onloadSelectAppId]);

	return (
		<div>
			List of apps:{" "}
			{apps.map((app) => {
				const isSelected = app.appId === payloadsContext.selectedAppId;
				return (
					<span key={app.appId}>
						<button
							disabled={isDisabled}
							style={{
								padding: "12px",
								margin: "4px 0 4px 4px",
								border: "1px solid grey",
								backgroundColor: isSelected ? "green" : "",
							}}
							onClick={() => selectApp(app.appId)}
						>
							{app.appId}
						</button>
						<button
							disabled={isDisabled}
							style={{
								padding: "12px 2px",
								margin: "4px 4px 4px 0",
								border: "1px solid grey",
							}}
							onClick={() => removeApp(app.appId)}
						>
							X
						</button>
					</span>
				);
			})}
		</div>
	);
}

export default AppsList;
