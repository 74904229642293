import { useCallback, useEffect, useId, useState } from "react";
import { usePayloadsContext } from "../contexts/PayloadsContext";
import CountdownBar from "./CountdownBar";

const REFRESH_INTERVAL_MS = 1200;

export default function PayloadsRefreshControls() {
	const payloadsContext = usePayloadsContext();
	const checkboxId = useId();
	const [autoRefreshEnabled, setAutoRefreshEnabled] = useState<boolean>(true);
	const [timeOfLastRefresh, setTimeOfLastRefresh] = useState<number>(-Infinity);

	const timeUntilRefresh = timeOfLastRefresh + REFRESH_INTERVAL_MS - Date.now();

	const refreshPayloads = useCallback(
		async function () {
			await payloadsContext.loadNewPayloads();
			setTimeOfLastRefresh(Date.now());
		},
		[payloadsContext]
	);

	useEffect(() => {
		if (autoRefreshEnabled && !payloadsContext.isLoading) {
			const timeout = window.setTimeout(refreshPayloads, timeUntilRefresh);
			return () => window.clearTimeout(timeout);
		}
	}, [autoRefreshEnabled, timeOfLastRefresh, payloadsContext, refreshPayloads, timeUntilRefresh]);

	return (
		<span>
			<button disabled={payloadsContext.isLoading || autoRefreshEnabled} onClick={() => refreshPayloads()}>
				Load new payloads
			</button>
			<label htmlFor={checkboxId}>Autorefresh: </label>
			<input id={checkboxId} checked={autoRefreshEnabled} onChange={(event) => setAutoRefreshEnabled(event.target.checked)} type="checkbox" />
			{autoRefreshEnabled && <CountdownBar timeMs={timeUntilRefresh} maxTimeMs={REFRESH_INTERVAL_MS} zeroMessage="........." />}
		</span>
	);
}
