import { useCallback } from "react";
import InputList, { InputListItem } from "./InputList";

const LOCALSTORAGE_FIELDS_TO_SHOW_CACHE = "payload_fields_to_show_arr";

interface ShowFieldsInputParameters {
	appId: string;
	onChange: (newFieldsToShow: string[]) => void;
}

export default function ShowFieldsInput({ appId, onChange }: ShowFieldsInputParameters) {
	const onInputListChanged = useCallback(
		(newItems: InputListItem[]) => {
			storeFieldsToShow(appId, newItems);
			onChange(newItems.filter((item) => item.enabled).map((item) => item.value));
		},
		[appId, onChange]
	);

	return (
		<div>
			<InputList title="Columns" initialItems={getStoredFieldsToShow(appId)} onChange={onInputListChanged} />
		</div>
	);
}

function getStoredFieldsToShow(appId: string): InputListItem[] {
	const fieldsCacheAny = JSON.parse(localStorage.getItem(LOCALSTORAGE_FIELDS_TO_SHOW_CACHE) || "{}") as any;
	return fieldsCacheAny[appId] || [];
}

function storeFieldsToShow(appId: string, fieldsToShow: InputListItem[]): void {
	const fieldsCacheAny = JSON.parse(localStorage.getItem(LOCALSTORAGE_FIELDS_TO_SHOW_CACHE) || "{}") as any;
	fieldsCacheAny[appId] = fieldsToShow;
	localStorage.setItem(LOCALSTORAGE_FIELDS_TO_SHOW_CACHE, JSON.stringify(fieldsCacheAny));
}
