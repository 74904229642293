import { useEffect, useState } from "react";
import PayloadList from "./components/PayloadList";
import { isAuthenticated as isAuthenticatedApi } from "./core/auth_api";
import LoginForm from "./components/LoginForm";
import { PayloadsProvider } from "./contexts/PayloadsContext";
import AppsManager from "./components/AppsManager";

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
	const [checkAuthentication, setCheckAuthentication] = useState<boolean>(true);

	useEffect(() => {
		if (!checkAuthentication) return;
		(async function () {
			setIsAuthenticated(await isAuthenticatedApi());
			setCheckAuthentication(false);
		})();
	}, [checkAuthentication]);

	return (
		<div>
			{isAuthenticated === null && "Loading..."}
			{isAuthenticated === false && <LoginForm onLogin={() => setCheckAuthentication(true)} />}
			{isAuthenticated === true && (
				<PayloadsProvider>
					<div>
						<AppsManager />
						<PayloadList />
					</div>
				</PayloadsProvider>
			)}
		</div>
	);
}

export default App;
