function getNestedValue(obj: any, structuredKey: string): unknown {
	let keyPath = structuredKey.replaceAll("\\.", "__TEMP_FOR_DOT__").split(".");
	keyPath = keyPath.map((key) => key.replaceAll("__TEMP_FOR_DOT__", "."));
	for (let keyIndex = 0; keyIndex < keyPath.length && obj !== null && obj !== undefined; keyIndex++) {
		obj = obj[keyPath[keyIndex]];
	}
	return obj ?? null;
}

export { getNestedValue };
