import { useState } from "react";
import { authenticate } from "../core/auth_api";

function LoginForm({ onLogin }: { onLogin: () => void }) {
	const [password, setPassword] = useState<string>("");
	const [inputDisabled, setInputDisabled] = useState<boolean>(false);

	const onSubmit = async () => {
		setInputDisabled(true);
		try {
			await authenticate(password);
			onLogin();
		} catch (error: any) {
			alert(error.message);
		}
		setInputDisabled(false);
	};

	return (
		<div>
			<form
				id="loginForm"
				onSubmit={(event) => {
					event.preventDefault();
					onSubmit();
				}}
			>
				<input
					type="password"
					disabled={inputDisabled}
					autoFocus
					placeholder="Password"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
				/>
				<button type="submit" disabled={inputDisabled}>
					Submit
				</button>
			</form>
		</div>
	);
}

export default LoginForm;
