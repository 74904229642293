function createOperatorFunction(operator: string): (actualValue: string, valueToMatch: string) => boolean {
	// prettier-ignore
	switch(operator){ 
        case "*=": return compareContains;
        case "^=": return compareStartsWith;
        case "$=": return compareEndsWith;
        case "~=": return compareRegex;
        case "=": return compareEquals;
        case "!=": return compareNotEquals;
        case '>': return compareLargerThan;
        case '>=': return compareLargerOrEqualThan;
        case '<': return compareLessThan;
        case '<=': return compareLessOrEqualThan;
        default: throw new Error(`Unexpected filter operator "${operator}"`);
    }
}

function compareContains(actualValue: string, valueToMatch: string): boolean {
	return actualValue.includes(valueToMatch);
}

function compareStartsWith(actualValue: string, valueToMatch: string): boolean {
	return actualValue.startsWith(valueToMatch);
}

function compareEndsWith(actualValue: string, valueToMatch: string): boolean {
	return actualValue.endsWith(valueToMatch);
}

function compareRegex(actualValue: string, valueToMatch: string): boolean {
	try {
		return new RegExp(valueToMatch).test(actualValue);
	} catch (e) {
		return false;
	}
}

function compareEquals(actualValue: string, valueToMatch: string): boolean {
	return actualValue === valueToMatch;
}

function compareNotEquals(actualValue: string, valueToMatch: string): boolean {
	return actualValue !== valueToMatch;
}

function compareLargerThan(actualValue: string, valueToMatch: string): boolean {
	return parseFloat(actualValue) > parseFloat(valueToMatch);
}

function compareLargerOrEqualThan(actualValue: string, valueToMatch: string): boolean {
	return parseFloat(actualValue) >= parseFloat(valueToMatch);
}

function compareLessThan(actualValue: string, valueToMatch: string): boolean {
	return parseFloat(actualValue) < parseFloat(valueToMatch);
}

function compareLessOrEqualThan(actualValue: string, valueToMatch: string): boolean {
	return parseFloat(actualValue) <= parseFloat(valueToMatch);
}

function isNumberOperator(str: string): boolean {
	// prettier-ignore
	switch(str) {
		case ">": return true;
		case ">=": return true;
		case "<": return true;
		case "<=": return true;
		default: return false;
	}
}

export { createOperatorFunction, isNumberOperator };
