import { createContext, useContext, useState, useCallback } from "react";
import IPayload from "../core/IPayload";
import { createApp, deleteApp, listApps, loadLastPayloads } from "../core/payloads_api";
import IPayloadApp from "../core/IPayloadApp";

const PayloadsContext = createContext<PayloadsContextType | undefined>(undefined);

export function PayloadsProvider({ children }: { children: any }) {
	const [payloads, setPayloads] = useState<IPayload[]>([]);
	const [payloadIndexAfterClear, setPayloadIndexAfterClear] = useState<number>(-1);
	const [apps, setApps] = useState<IPayloadApp[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isFirstload, setIsFirstLoad] = useState<boolean>(true);
	const [selectedAppId, setSelectedAppId] = useState<string>("");

	const loadNewPayloads = useCallback(async () => {
		setIsLoading(true);
		const lastIndex = payloads.reduce((max, payload) => Math.max(max, payload.index), payloadIndexAfterClear);
		const newPayloads = await loadLastPayloads(selectedAppId, isFirstload ? undefined : lastIndex + 1);
		setPayloads([...payloads, ...newPayloads]);
		setIsLoading(false);
	}, [payloads, isFirstload, selectedAppId, payloadIndexAfterClear]);

	const loadApps = useCallback(async () => {
		setIsLoading(true);
		const newApps = await listApps();
		setApps(newApps);
		setIsLoading(false);
	}, []);

	const createNewApp = useCallback(
		async (appId: string) => {
			setIsLoading(true);
			await createApp(appId);
			setIsLoading(false);
			await loadApps();
		},
		[loadApps]
	);

	const removeApp = useCallback(
		async (appId: string) => {
			setIsLoading(true);
			await deleteApp(appId);
			if (appId === selectedAppId) {
				setSelectedAppId("");
			}
			setIsLoading(false);
			await loadApps();
		},
		[loadApps, setSelectedAppId, selectedAppId]
	);

	const selectApp = useCallback(async (appId: string): Promise<void> => {
		setPayloads([]);
		setSelectedAppId(appId);
		setPayloadIndexAfterClear(-1);
		setIsFirstLoad(true);
	}, []);

	const clearPayloads = useCallback(async (): Promise<void> => {
		const lastIndex = payloads.reduce((max, payload) => Math.max(max, payload.index), -1);
		setPayloadIndexAfterClear(lastIndex);
		setPayloads([]);
	}, [payloads]);

	if (isFirstload) {
		if (selectedAppId) {
			loadNewPayloads();
			setIsFirstLoad(false);
		}
	}

	return (
		<PayloadsContext.Provider
			value={{
				payloads: payloads,
				apps: apps,
				isLoading: isLoading,
				selectedAppId: selectedAppId,
				loadNewPayloads: loadNewPayloads,
				loadApps: loadApps,
				createNewApp: createNewApp,
				removeApp: removeApp,
				selectApp: selectApp,
				clearPayloads: clearPayloads,
			}}
		>
			{children}
		</PayloadsContext.Provider>
	);
}

export function usePayloadsContext(): PayloadsContextType {
	const context = useContext(PayloadsContext);
	if (!context) {
		throw new Error("Payloads context in invalid location");
	}
	return context;
}

interface PayloadsContextType {
	payloads: IPayload[];
	apps: IPayloadApp[];
	isLoading: boolean;
	selectedAppId: string;
	loadNewPayloads: () => Promise<void>;
	loadApps: () => Promise<void>;
	createNewApp: (appId: string) => Promise<void>;
	removeApp: (appId: string) => Promise<void>;
	selectApp: (appId: string) => Promise<void>;
	clearPayloads: () => Promise<void>;
}
