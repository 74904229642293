import { useCallback, useMemo, useState } from "react";
import { getNestedValue } from "../util/objects";
import { createOperatorFunction, isNumberOperator } from "../util/operators";
import InputList, { InputListItem } from "./InputList";

const LOCALSTORAGE_FILTER_CACHE = "filter_strings_arr";

interface FilterStringInputParameters<T> {
	appId: string;
	keyPrefix?: string;
	onChange: (newFilterFunction: FilterFunction<T>) => void;
}

export default function FilterStringInput<T>({ appId, onChange, keyPrefix = "" }: FilterStringInputParameters<T>) {
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	const updateFilterString = useCallback(
		(filterItems: InputListItem[]) => {
			storeFilterItems(appId, filterItems);
			const newFilterString = filterItems
				.filter((item) => item.enabled)
				.map((item) => item.value)
				.join("&&");
			const filters = getFiltersFromString(newFilterString);
			setHasErrors(!isValidFilterString(filters));
			onChange(createFilterFunction(filters, keyPrefix));
		},
		[appId, onChange, keyPrefix]
	);

	const initialItems = useMemo(() => getStoredFilterItems(appId) || [], [appId]);

	return (
		<div>
			<InputList
				title="Filters"
				initialItems={initialItems}
				updateOnInitialItemsChange
				newFieldPlaceholder="event_name*=form"
				infoLink={`${process.env.PUBLIC_URL}/comparators.txt`}
				onChange={updateFilterString}
			/>
			{hasErrors && <div style={{ color: "#ffb3ad" }}>There is an error in the filters.</div>}
		</div>
	);
}

function getStoredFilterItems(appId: string): InputListItem[] | null {
	const filterCacheAny = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTER_CACHE) || "{}") as any;
	return filterCacheAny[appId] || null;
}

function storeFilterItems(appId: string, filterItems: InputListItem[]): void {
	const filterCacheAny = JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTER_CACHE) || "{}") as any;
	filterCacheAny[appId] = filterItems;
	localStorage.setItem(LOCALSTORAGE_FILTER_CACHE, JSON.stringify(filterCacheAny));
}

function createFilterFunction<T>(filters: string[], prefix: string): FilterFunction<T> {
	return (arr) => filterItems(arr, filters, prefix) || arr;
}

function isValidFilterString(filters: string[]): boolean {
	return filterItems([], filters, "") !== null;
}

function getFiltersFromString(filterString: string): string[] {
	return filterString
		.split("&&")
		.map((s) => s.trim())
		.filter(Boolean);
}

function filterItems<T>(items: T[], filters: string[], prefix: string): T[] | null {
	if (filters.length === 0) return items;
	for (let i = 0; i < filters.length; i++) {
		const filter = filters[i];
		const match = filter.match(/^([^=]+?)\s*((?:\$|\^|!|\*|~)?=|>=?|<=?)\s*(.+)/);
		if (!match) return null;
		const key = match[1];
		const operator = match[2];
		const value = match[3].trim();
		if (operator === "~=") {
			try {
				RegExp(value);
			} catch (e) {
				return null;
			}
		}
		if (isNumberOperator(operator) && isNaN(parseFloat(value))) return null;
		items = applyItemFilter(items, prefix + key, operator, value);
	}
	return items;
}

function applyItemFilter<T>(items: T[], key: string, operator: string, valueToMatch: string): T[] {
	valueToMatch = valueToMatch.toLowerCase();
	const filterFunction = createOperatorFunction(operator);
	return items.filter((item) => {
		let actualValue: string = (getNestedValue(item, key) ?? "null").toString().toLowerCase();
		return filterFunction(actualValue, valueToMatch);
	});
}

type FilterFunction<T> = (arr: T[]) => T[];

export type { FilterFunction };
