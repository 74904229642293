interface IPayloadApp {
	appId: string;
}

function assertIsIPayloadApp(obj: any) {
	if (typeof obj.appId !== "string") throw new Error(`Expected payload object to have "appId" string value`);
	return true;
}

export default IPayloadApp;
export { assertIsIPayloadApp };
