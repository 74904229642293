import { useEffect, useMemo, useState } from "react";

const TOTAL_BARS = 10;

export default function CountdownBar({ timeMs, maxTimeMs, zeroMessage }: { timeMs: number; maxTimeMs: number; zeroMessage?: string }) {
	const goalTime = useMemo<number>(() => Date.now() + Math.max(timeMs, 0), [timeMs]);
	const [currentTime, setCurrentTime] = useState<number>(Date.now());
	const timeLeft = Math.max(goalTime - currentTime, 0);

	useEffect(() => {
		const timeout = window.setTimeout(() => setCurrentTime(Date.now()), 50);
		return () => window.clearTimeout(timeout);
	}, [currentTime]);

	const percentageTime = timeLeft / maxTimeMs;
	const bars = Math.floor(percentageTime * TOTAL_BARS);
	const barsString = new Array(Math.max(bars, 0)).fill("l").join("");
	return <span style={{ fontFamily: "arial" }}>{percentageTime > 0 ? barsString : zeroMessage}</span>;
}
