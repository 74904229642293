import { useCallback, useEffect, useState } from "react";

export interface InputListItem {
	value: string;
	enabled: boolean;
}

interface InputListParameters {
	title?: string;
	initialItems?: InputListItem[];
	updateOnInitialItemsChange?: boolean;
	newFieldPlaceholder?: string;
	infoLink?: string;
	onChange: (newItems: InputListItem[]) => void;
}

export default function InputList({ title, initialItems, updateOnInitialItemsChange, newFieldPlaceholder, infoLink, onChange }: InputListParameters) {
	const [items, setItems] = useState<InputListItem[]>(initialItems || []);

	const updateItem = useCallback(
		(indexToChange: number, newValue: string) => {
			setItems(
				items.map((item, index) => {
					if (index !== indexToChange) return item;
					return { value: newValue, enabled: item.enabled };
				})
			);
		},
		[items]
	);

	const toggleItemEnabled = useCallback(
		(indexToToggle: number) => {
			setItems(
				items.map((item, index) => {
					if (index !== indexToToggle) return item;
					return { value: item.value, enabled: !item.enabled };
				})
			);
		},
		[items]
	);

	useEffect(() => {
		if (updateOnInitialItemsChange && initialItems) {
			setItems(initialItems);
		}
	}, [initialItems, updateOnInitialItemsChange]);

	useEffect(() => {
		onChange(items);
	}, [items, onChange]);

	return (
		<div style={{ margin: "16px 0" }}>
			{title && <span>{title} </span>}
			{infoLink && (
				<a style={{ marginLeft: "6px" }} target="_blank" rel="noreferrer" href={infoLink}>
					Info
				</a>
			)}
			{items.map((item, index) => (
				<div key={index}>
					<input type="checkbox" checked={item.enabled} onChange={() => toggleItemEnabled(index)} />
					<input
						type="text"
						value={item.value}
						placeholder={newFieldPlaceholder}
						onChange={(event) => updateItem(index, event.target.value)}
						style={{ backgroundColor: "#adadad", width: "500px" }}
					/>
					<button onClick={() => setItems(items.filter((_, i) => i !== index))}>X</button>
				</div>
			))}
			<div>
				<button onClick={() => setItems([...items, { value: "", enabled: true }])}>Add</button>
			</div>
		</div>
	);
}
