import { useState } from "react";
import { usePayloadsContext } from "../contexts/PayloadsContext";
import Form from "./Form";

function CreateNewApp() {
	const payloadsContext = usePayloadsContext();
	const [newAppId, setNewAppId] = useState<string>("");

	const createNewApp = async (appId: string) => {
		appId = appId.trim().replace(/\s+/g, " ");
		setNewAppId(appId);
		if (!appId) {
			return;
		}
		if (payloadsContext.apps.find((app) => app.appId === appId)) {
			alert("An app with this id already exists");
			return;
		}
		await payloadsContext.createNewApp(appId);
		setNewAppId("");
	};

	return (
		<div>
			Create new app:
			<Form onSubmit={() => createNewApp(newAppId)}>
				<input type="text" value={newAppId} onChange={(event) => setNewAppId(event.target.value)} placeholder="New App ID" />
				<button type="submit">Submit</button>
			</Form>
		</div>
	);
}

export default CreateNewApp;
